export const INIT_SEARCH = 'INIT_SEARCH';

export const GET_AUTOCOMPLETE_SUGGESTION_START =
  'GET_AUTOCOMPLETE_SUGGESTION_START';
export const GET_AUTOCOMPLETE_SUGGESTION_SUCCESS =
  'GET_AUTOCOMPLETE_SUGGESTION_SUCCESS';
export const GET_AUTOCOMPLETE_SUGGESTION_FAIL =
  'GET_AUTOCOMPLETE_SUGGESTION_FAIL';

export const PERFORM_SEARCH_START = 'PERFORM_SEARCH_START';
export const PERFORM_SEARCH_SUCCESS = 'PERFORM_SEARCH_SUCCESS';
export const PERFORM_SEARCH_FAIL = 'PERFORM_SEARCH_FAIL';

export const GET_NEXT_RESULTS_PAGE_START = 'GET_NEXT_RESULTS_PAGE_START';
export const GET_NEXT_RESULTS_PAGE_SUCCESS = 'GET_NEXT_RESULTS_PAGE_SUCCESS';
export const GET_NEXT_RESULTS_PAGE_FAIL = 'GET_NEXT_RESULTS_PAGE_FAIL';

export const YOUTUBE_LOOKUP_START = 'YOUTUBE_LOOKUP_START';
export const YOUTUBE_LOOKUP_SUCCESS = 'YOUTUBE_LOOKUP_SUCCESS';
export const YOUTUBE_LOOKUP_FAIL = 'YOUTUBE_LOOKUP_FAIL';

export const PLAYLIST_SEARCH = 'lor:search';
export const YOUTUBE_DAPI_KEY = 'AIzaSyAfDoFfKhEL-tiCsX-BtpuG6pW7G7YKV_M';
